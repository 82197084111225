import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../layout/index.en";
import config from "../../data/SiteConfig";
import MoreDetails from "../components/MoreDetails/more.en";
import Features from "../components/Features/features.en";
import Banner from "../components/Banner/Banner.en"
import FAQ from "../components/FAQ/FAQ.en"
import "./index.css";


class HomePage extends Component {
  render() {
    return (
      <Layout>
        <div className="home">
          <HelmetProvider>
            <Helmet title={`Home | ArgoVPN`} />
          </HelmetProvider>
          <div className="homeContainer">
            <Banner />
            <MoreDetails />
            <Features />
            {/* <FAQ /> */}
          </div>         
        </div>
      </Layout>
    );
  }
}

export default HomePage;
