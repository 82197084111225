import React, { Component } from "react";
import { Link } from "gatsby";

import "./more.css";

class MoreDetails extends Component {
  render() {
    return (
    	<div className="more">
        <h2>What is ArgoVPN?</h2>
        <p>ArgoVPN is a free VPN with an unlimited bandwidth that is developed for Android devices. ArgoVPN allows users to visit blocked websites, online services, social media and messaging apps. ArgoVPN is developed according to Iranian citizens' needs to bypass internet censorship in Iran with premium features, which users can find in commercial VPNs.</p>
        <Link to="/en/blog">More...</Link>
      </div>
    );
  }
}

export default MoreDetails;
