import React, { Component } from "react";
import firewall from './home/firewall.png'
import customize from './home/customize.png'
import vpn from './home/vpn.png'


import "./features.css";

class Features extends Component {
  render() {
    return (
			<div className="features">
        <div className="featuresContainer">
          <div>
            <img src={vpn} alt=""/>
            <h2>Free and Unlimited</h2>
            <p>Having the free and unlimited features of premium VPNs </p>
          </div>
          <div>
            <img src={firewall} alt=""/>
            <h2>Firewall and Secure</h2>
            <p>Customization of Firewall and Managing traffics of installed apps</p>
          </div>
          <div>
            <img src={customize} alt=""/>
            <h2>Personalized VPN</h2>
            <p>Setup private bridges on personal domain names</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
